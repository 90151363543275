<template>
    <form
        class="header__search"
        spellcheck="false"
        :class="{ 'is-loading': loading }"
        @submit.prevent="onSearch()"
    >
        <input
            ref="inputEl"
            v-model="search"
            type="text"
            class="header__search__input"
            :placeholder="$t('SearchFlyout.search_placeholder')"
            aria-label="Search"
            data-testid="headerSearchInput"
            @focus="onInputFocus"
            @blur.passive="onInputBlur()"
        >
        <button
            type="button"
            class="header__search__remove"
            :class="{ 'is-visible': search.length }"
            :title="$t('SearchFlyout.delete')"
            @click="onReset()"
        >
            <AtomIcon v-if="search.length" name="x" width="24"/>
        </button>
        <button class="header__search__submit" :title="$t('SearchFlyout.action')">
            <AtomIcon name="search" class="header__search__submit__icon"/>
            <AtomIcon name="loader" class="header__search__submit__loader"/>
        </button>

        <Transition name="is">
            <div v-if="opened && !isEmptyData()" ref="suggestionsEl" class="suggestions">
                <div class="flex-2">
                    <div class="suggestions__headline">{{ $t('SearchFlyout.suggestions') }}</div>

                    <ul class="suggestions__completion">
                        <template v-for="(item, index) in filteredCompletion" :key="index">
                            <li>
                                <NuxtLink
                                    :to="`${NuxtLinkNameTypes.CATALOG_SEARCH}?q=${item}`"
                                    :class="{
                                        'is-active': activeItem === index,
                                    }"
                                    v-html="$sanitizeHtml(completionToHTML(item))"/>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="flex-1">
                    <div class="suggestions__headline">{{ $t('SearchFlyout.products') }}</div>

                    <ul v-if="data" class="suggestions__products">
                        <template
                            v-for="(product, index) in data.abstractProducts?.slice(0, maxProducts)"
                            :key="index"
                        >
                            <li
                                class="suggestions__product"
                                @click="navigate(`${NuxtLinkNameTypes.PRODUCT}/${product.abstractSku}`)">
                                <div class="suggestions__product__image">
                                    <NuxtLink
                                        :to="`${NuxtLinkNameTypes.PRODUCT}/${product.abstractSku}`">
                                        <img
                                            :src="product.images?.[0]?.externalUrlSmall"
                                            :alt="product.abstractName"
                                            :title="product.abstractName"
                                        >
                                        <AtomLotDisplayOverlay
                                            v-if="isLotOrDisplayType(product)"
                                            :product-type="product?.productType || ''"
                                            :customer-group-access="true"
                                            group-type="search-flyout"
                                        />
                                    </NuxtLink>
                                </div>

                                <div class="suggestions__product__details">
                                    <div class="suggestions__product__labels">
                                        <div
                                            v-if="!isLotOrDisplayType(product)"
                                            class="suggestions__product__brand suggestions__product__label">
                                            {{ product.brand }}
                                        </div>

                                        <template v-for="label in product.labels" :key="label">
                                            <AtomProductLabel
                                                v-if="!disabledLabels.includes(label)"
                                                :text="label"
                                                class="suggestions__product__label"/>
                                        </template>
                                    </div>
                                    <span class="suggestions__product__title">
                                        <span>{{
                                                $t('Product.m_number')
                                            }}: {{ product.modelNoFrontend }}</span>
                                        <span>|</span>
                                        <span :title="product.abstractName">{{
                                                product.abstractName
                                            }}</span>
                                    </span>
                                </div>

                                <div class="suggestions__product__prices">
                                    <AtomProductPrices :product="product"/>
                                </div>

                                <div class="suggestions__product__buttons">
                                    <AtomButton
                                        type="secondary"
                                        icon="arrow-right"
                                        size="m"
                                        @click.stop="navigate(`${NuxtLinkNameTypes.PRODUCT}/${product.abstractSku}`)"
                                    />
                                    <AtomButton
                                        v-if="$can(PermissionTypes.ORDER)"
                                        v-role:not="RoleTypes.SUPPLIER"
                                        type="primary"
                                        class="plp-tile-item__add-to-basket"
                                        icon="basket"
                                        size="m"
                                        :title="$t('Product.add_to_cart')"
                                        @click.stop="openMatrix(product.idProductAbstract, product.abstractSku)"
                                    />
                                </div>
                            </li>
                        </template>
                    </ul>

                    <AtomButton
                        v-show="data?.abstractProducts && data.abstractProducts.length > maxProducts"
                        class="suggestions__show-all"
                        :text="$t('SearchFlyout.show_all_products')"
                        @click="navigate(`${NuxtLinkNameTypes.CATALOG_SEARCH}?q=${search}`)"
                    />
                </div>
            </div>
        </Transition>
    </form>
</template>

<script lang="ts" setup>
import type {
    CatalogSearchSuggestionsProduct,
    CatalogSearchSuggestionsResult
} from '~/composables/types/api/searchDiscover/catalogSearchSuggestions';
import { DISPLAYS, LOTS } from '~/composables/utils/applicationConstants';
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes';
import { PermissionTypes } from '~/composables/types/permissionTypes';
import { RoleTypes } from '~/composables/types/roleTypes';

const { $can, $emitter, $sanitizeHtml } = useNuxtApp();
const route = useRoute();
const toasts = useToasts();
const { load, getResult, abort, wasAborted } = useCatalogSearchSuggestions();

const isLotOrDisplayType = (product: CatalogSearchSuggestionsProduct) => computed(() => (
    product?.productType && (LOTS.includes(product?.productType) || DISPLAYS.includes(product?.productType))));

const props = withDefaults(defineProps<{
    minLength?: number,
    loadTimer?: number,
    refreshTimer?: number,
    maxSuggestions?: number,
    maxProducts?: number,
    scannerOptions?: any,
}>(), {
    minLength: 3,
    loadTimer: 400,
    refreshTimer: 3000,
    maxSuggestions: 10,
    maxProducts: 4,
    scannerOptions: undefined,
});

const scannerOptionsRef = ref({
    scannerDelay: 200,
    stringWriting: '',
    scannerDetected: false,
});

const inputEl = ref();
const suggestionsEl = ref();
const search = ref('');
const loading = ref(false);
const opened = ref(false);
const activeItem = ref(-1);
const activeSearchBar = ref(false);
const preventClose = ref(false);
const data: Ref<CatalogSearchSuggestionsResult | null> = ref(null);
let lastSearch = '';
let timer: ReturnType<typeof setTimeout>;
let refreshSearchFlyoutTimer: ReturnType<typeof setTimeout>;
let preventLoad = false;
const disabledLabels = ['NETTO'];

const isEmptyData = () => {
    if (data.value) {
        return !data.value.completion?.length && !data.value.abstractProducts?.length;
    }

    return true;
};

const navigate = (link: string) => {
    navigateTo(link);
};

const autoSetActiveItem = () => {
    if (data.value) {
        activeItem.value = data.value.completion?.findIndex((item) => item.trim().toLowerCase() === search.value.trim().toLowerCase()) ?? -1;
    } else {
        activeItem.value = -1;
    }
};

const fetchData = async(newValue: string) => {
    loading.value = true;

    if (await load(newValue)) {
        data.value = getResult() as CatalogSearchSuggestionsResult;
        loading.value = false;
        lastSearch = newValue;

        if (!isEmptyData()) {
            autoSetActiveItem();
            opened.value = true;
        } else {
            opened.value = false;
        }
    } else {
        loading.value = false;

        if (!wasAborted()) {
            data.value = null;
            opened.value = false;
            toasts.add('LOAD_ERROR');
        }
    }
};

const filteredCompletion = computed(() => {
    if (data.value) {
        return data.value.completion?.filter((item) => item.toLowerCase().includes(search.value.toLowerCase()))
            .slice(0, props.maxSuggestions);
    }

    return [];
});

const completionToHTML = (completion: string) => {
    const regex = new RegExp(`(${search.value})`, 'gi');

    return completion.replace(regex, '<span>$1</span>');
};

const onSearch = () => {
    const query = { q: search.value };

    abort();
    opened.value = false;
    activeItem.value = -1;
    inputEl.value.blur();

    navigateTo({
        path: NuxtLinkNameTypes.CATALOG_SEARCH,
        query: query,
    });
};

const onReset = () => {
    search.value = '';
    lastSearch = '';
    opened.value = false;
    data.value = null;
    inputEl.value.focus();
};

const onInputFocus = () => {
    activeSearchBar.value = true;

    if (data.value) {
        if (search.value.length && search.value === lastSearch && !isEmptyData()) {
            opened.value = true;
        }
    } else if (search.value.length >= props.minLength) {
        fetchData(search.value);
    } else {
        opened.value = false;
    }

    preventClose.value = false;
};

const onInputBlur = () => {
    activeSearchBar.value = false;

    setTimeout(() => {
        if (!preventClose.value) {
            abort();
            opened.value = false;
        }
    }, 100);
};

const openMatrix = (productId: number, sku: string) => {
    opened.value = false;

    $emitter.$emit('openMatrix', {
        abstractProductIdOrSku: productId,
        sku: sku,
    });
};

const identifyInputType = (event: KeyboardEvent) => {
    scannerOptionsRef.value.stringWriting += event.key;
    const minBarcodeLength = 12;
    if (!scannerOptionsRef.value.scannerDetected) {
        if (scannerOptionsRef.value.stringWriting.length >= minBarcodeLength) {
            scannerOptionsRef.value.scannerDetected = true;
        }

        setTimeout(() => {
            scannerOptionsRef.value.scannerDetected = false;
            scannerOptionsRef.value.stringWriting = '';
        }, scannerOptionsRef.value.scannerDelay);
    }
};

const handleSearchSubmit = () => {
    if (scannerOptionsRef.value.scannerDetected) {
        preventLoad = true;

        if (!search.value && !activeSearchBar.value) {
            search.value = scannerOptionsRef.value.stringWriting;
        } else if (!activeSearchBar.value) {
            search.value += scannerOptionsRef.value.stringWriting.replace(
                new RegExp(`${scannerOptionsRef.value.stringWriting}$`),
                ` ${scannerOptionsRef.value.stringWriting}`,
            );
        }
        scannerOptionsRef.value.stringWriting = '';
        scannerOptionsRef.value.scannerDetected = false;

        onSearch();
    }

    if (!scannerOptionsRef.value.scannerDetected && opened.value && activeItem.value > -1) {
        preventLoad = true;
        if (filteredCompletion.value && filteredCompletion.value[activeItem.value]) {
            lastSearch = search.value;
        }

        onSearch();
    }
};

const onKeyPress = (event: KeyboardEvent): void => {
    switch (event.key) {
    case 'Escape':
        if (!opened.value) {
            return;
        }

        opened.value = false;
        break;
    case 'ArrowDown':
        event.preventDefault();

        if (!opened.value && !isEmptyData()) {
            opened.value = true;

            return;
        }

        if (filteredCompletion.value && activeItem.value < filteredCompletion.value.length - 1) {
            activeItem.value = Math.min(activeItem.value + 1, filteredCompletion.value.length - 1);
        } else {
            activeItem.value = 0;
        }
        break;
    case 'ArrowUp':
        event.preventDefault();

        if (!opened.value) {
            return;
        }

        if (activeItem.value > 0) {
            activeItem.value = Math.max(activeItem.value - 1, 0);
        } else {
            activeItem.value = filteredCompletion.value ? filteredCompletion.value.length - 1 : -1;
        }
        break;
    case 'Enter':
        handleSearchSubmit();
        break;
    case 'Tab':
        handleSearchSubmit();
        break;
    default:
        identifyInputType(event);

        break;
    }
};

const onWindowMousedown = (e: MouseEvent) => {
    if (opened.value && suggestionsEl.value) {
        const trigger = e.target as HTMLLinkElement | HTMLButtonElement;

        if (!trigger!.matches('a[href], button') && (suggestionsEl.value === trigger || trigger!.closest('.header__search'))) {
            preventClose.value = true;
        } else {
            opened.value = false;
        }
    }
};

watch(() => route.path, () => {
    opened.value = false;

    if (route.path === NuxtLinkNameTypes.CATALOG_SEARCH) {
        abort();
        preventLoad = true;
        search.value = route.query.q as string ?? '';
        lastSearch = search.value;
    }
});

watch(search, (newValue) => {
    abort();

    if (timer) {
        clearTimeout(timer);
    }

    if (newValue !== lastSearch) {
        preventLoad = false;
    }

    if (newValue.length >= props.minLength) {
        if (preventLoad) {
            preventLoad = false;
        } else {
            timer = setTimeout(() => {
                if (preventLoad) {
                    return;
                }

                fetchData(newValue);
            }, props.loadTimer);
        }
    } else {
        loading.value = false;
        opened.value = false;
    }
});

watch(opened, () => {
    clearTimeout(refreshSearchFlyoutTimer);

    if (!opened.value) {
        preventClose.value = false;

        refreshSearchFlyoutTimer = setTimeout(() => {
            data.value = null;
        }, props.refreshTimer);
    }

    autoSetActiveItem();
});

onMounted(() => {
    if (route.path === NuxtLinkNameTypes.CATALOG_SEARCH) {
        preventLoad = true;
        search.value = route.query.q as string ?? '';
        lastSearch = search.value;
    }

    window.addEventListener('keypress', onKeyPress);
    window.addEventListener('mousedown', onWindowMousedown);
});

onBeforeUnmount(() => {
    window.removeEventListener('keypress', onKeyPress);
    window.removeEventListener('mousedown', onWindowMousedown);
});
</script>

<style lang="scss" scoped>
.header__search {
    position: relative;

    &__input {
        width: 100%;
        padding: rem(10) rem(30) rem(10) sp(s);
        transition: border-color 0.2s ease;

        @include helper-color(text-title);
        @include helper-font-size(big);
        @include helper-border($setting-color-input-border, rem(1), solid);
        @include helper-border-radius($setting-border-radius-input);

        &:focus {
            outline: none;
            border-color: $setting-color-corporate-blue;
        }
    }

    &__submit {
        position: absolute;
        right: 0.75rem;
        top: 0.675rem;
        background-color: transparent;
        cursor: pointer;

        &__icon,
        &__loader {
            transition: transform 0.8s, opacity 0.8s;
            transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

            :deep(svg) {
                @include helper-color(corporate-blue);
            }
        }

        &__icon {
            transition-delay: 0.15s;
        }

        &__loader {
            position: absolute;
            left: 0;
            top: 0.25rem;
            opacity: 0;
            transform: scale(1.25);

            :deep(svg) {
                animation: spin-to 2s linear infinite; /* stylelint-disable-line */
            }
        }
    }

    &__remove {
        position: absolute;
        right: rem(36);
        top: rem(10);
        width: rem(20);
        height: rem(20);
        background-color: transparent;
        cursor: pointer;
        pointer-events: none;

        &.is-visible {
            pointer-events: all;

            i {
                &::before {
                    transition-duration: 0.85s;
                    transform: rotate(45deg) translate(0, 0) scale(1);
                }

                &::after {
                    transition-duration: 0.85s;
                    transition-delay: 0.15s;
                    transform: rotate(-45deg) translate(0, 0) scale(1);
                }
            }
        }
    }

    &.is-loading {
        .header__search {
            &__submit {
                &__icon {
                    transform: scale(0);
                    opacity: 0;
                    transition-delay: 0s;
                }

                &__loader {
                    opacity: 1;
                    transform: scale(1);
                    transition-delay: 0.15s;
                }
            }
        }
    }
}

.suggestions {
    @include helper-color-bg(white);

    position: absolute;
    display: flex;
    align-items: flex-start;
    top: 100%;
    left: 50%;
    width: rem(996);
    max-width: 100vw;
    margin-top: 1rem;
    margin-left: rem(-498);
    padding: 1.5rem;
    gap: 2rem;
    border-radius: $setting-border-radius-10;
    box-shadow: 0 rem(2) rem(8) 0 rgb(0 0 0 / 15%);
    z-index: 800;

    > div:first-child {
        width: rem(312);
    }

    &.is-enter-active,
    &.is-leave-active {
        transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        transition-property: transform, opacity;
    }

    &.is-enter-from,
    &.is-leave-to {
        opacity: 0;
        transform: translateY(0.675rem);
    }

    &__headline {
        margin-bottom: 1.5rem;

        @include helper-font-size(big);
        @include helper-font-weight(medium);
    }

    &__completion {
        a {
            position: relative;
            display: block;

            @include helper-color(black);
            @include helper-font-weight(medium);

            :deep(span) {
                @include helper-font-weight(light);
            }

            &:hover,
            &.is-active {
                &::after {
                    content: '';
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: rem(2);
                    left: -1.5rem;
                    right: 0;
                    z-index: -1;

                    @include helper-color-bg(light-gray);
                }
            }
        }
    }

    &__product {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.675rem 1rem;
        border-radius: $setting-border-radius-10;
        cursor: pointer;
        overflow: hidden;
        transition: 0.15s ease;
        transition-property: background-color, box-shadow;

        @include helper-border($setting-color-gray-1);

        & + & {
            margin-top: 1rem;
        }

        &__image {
            position: relative;
            width: rem(68);
            height: rem(68);
            margin-top: -0.675rem;
            margin-bottom: -0.675rem;
            margin-left: -1rem;
            padding: 0.5rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &:hover {
            box-shadow: 0 0 0 rem(1) $setting-color-gray-3;

            @include helper-color-bg(light-gray);
        }

        &__details {
            flex: 1;
        }

        &__labels {
            display: flex;
            flex-wrap: wrap;

            .icon {
                height: rem(12);
                width: rem(12);
            }
        }

        &__label {
            height: rem(24);
            padding: sp(xxs) sp(xs);
            margin: sp(xxs) sp(xxs) sp(xxs) 0;
            display: inline-flex;
            align-items: center;

            @include helper-border-radius(rem(16));
            @include helper-font-line-height(5);
            @include helper-font-size(smaller);
        }

        &__brand {
            @include helper-color-bg(gray-3);
            @include helper-color(gray-5);
        }

        &__title {
            display: inline-block;
            width: rem(312);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @include helper-color(text-title);
            @include helper-font-size(small);

            span {
                vertical-align: middle;

                & + span {
                    margin-left: 0.25rem;
                }
            }
        }

        &__prices {
            padding-right: 0.5rem;
            text-align: right;

            .prices {
                display: block;

                :deep(.hidden) {
                    display: none;
                }
            }
        }

        &__buttons {
            display: flex;
            gap: 0.5rem;
        }
    }

    &__show-all {
        margin-top: 1rem;
    }
}
</style>
