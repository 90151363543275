<template>
    <Teleport to="body">
        <div
            v-if="opened"
            ref="el"
            class="toast"
            :class="{ [`toast--${type}`]: true, [`toast--${zPosition}`]: !!zPosition }"
        >
            <div class="toast__body">
                <div class="toast__icon">
                    <AtomIcon :name="icon" width="32" />
                </div>
                <div class="toast__content">
                    <div v-if="headline" class="toast__headline" v-text="headline"/>
                    <div v-if="text" class="toast__text" v-text="text"/>
                    <div v-if="buttons && buttons!.length" class="toast__buttons">
                        <template v-for="(button, index) in buttons" :key="index">
                            <button type="button" @click="emit('buttonClick', index, close)">
                                {{ button }}
                            </button>
                        </template>
                    </div>
                </div>
                <button type="button" class="toast__close" @click="close()">
                    <AtomIcon name="x" width="24" />
                </button>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts" setup>
import type { ToastStoreItem } from '~/composables/stores/useToast';

const props = withDefaults(defineProps<{
    type: 'success' | 'warning' | 'error' | 'info',
    headline?: string,
    text?: string,
    timeout?: number,
    autoHide?: boolean,
    buttons?: string[],
    allowReopen?: boolean,
}>(), {
    headline: undefined,
    text: undefined,
    buttons: undefined,
    autoHide: true,
    timeout: 5000,
    allowReopen: true,
});

const emit = defineEmits<{
    (e: 'buttonClick', index: number, close: () => void): void,
    (e: 'close'): void
}>();

const { $emitter } = useNuxtApp();
const toastsStore = useToasts();
const autoHideTimer = props.timeout;
const autoHideTimerLeftMin = 1000;
const fadeInDelay = 200;
const transitionDuration = 300;
const MagicNumbers = {
    one: 1,
    two: 2,
    three: 3,
};

const el = ref();
const opened = ref(true);
const zPosition: Ref<'front' | 'middle' | 'last' | 'out' | undefined> = ref();
let autoHideTimeout: ReturnType<typeof setTimeout> | null;
let autoHideTimerLeft = autoHideTimer;
let autoHideTimerStart: number;

const icon = computed(() => {
    switch (props.type) {
    case 'success':
        return 'check';
    case 'warning':
        return 'alert-triangle';
    case 'error':
        return 'alert-octagon';
    case 'info':
        return 'bell';
    default:
        return '';
    }
});

const rearrange = (toastElement?: Element) => {
    let toasts = Array.from(document.querySelectorAll('body > .toast'));

    if (toastElement) {
        toasts = toasts.filter(element => element !== toastElement);
    }

    if (!toasts.length) {
        return;
    }

    toasts.map(() => {
        $emitter.$emit('toast.z-position', [toasts[toasts.length - MagicNumbers.one], '']);
    });

    setTimeout(() => {
        $emitter.$emit('toast.z-position', [toasts[toasts.length - MagicNumbers.one], 'front']);
    }, toastElement ? 0 : fadeInDelay);

    if (toasts.length > MagicNumbers.one) {
        $emitter.$emit('toast.z-position', [toasts[toasts.length - MagicNumbers.two], 'middle']);
    }

    if (toasts.length > MagicNumbers.two) {
        $emitter.$emit('toast.z-position', [toasts[toasts.length - MagicNumbers.three], 'last']);
    }
};

const close = () => {
    if (autoHideTimeout) {
        clearTimeout(autoHideTimeout);
    }

    zPosition.value = 'out';
    rearrange(el.value);

    setTimeout(() => {
        opened.value = false;
        autoHideTimeout = null;
        emit('close');
    }, transitionDuration);

    if (!props.allowReopen) {
        toastsStore.setToastClosedState(true);
    }
};

const findToastByMessage = (
    message: string
): ToastStoreItem | undefined => toastsStore.getToasts().find(
    (toastItem: ToastStoreItem) => toastItem.bind.text === message
);

const onZPositionUpdate = (info: any) => {
    if (info[0] === el.value) {
        zPosition.value = info[1];
    }
};

watch(zPosition, (newValue) => {
    const activeToast = findToastByMessage(props.text ?? '');

    if (activeToast?.bind.autoHide !== undefined && !activeToast?.bind.autoHide) {
        return;
    }

    if (newValue === 'front') {
        autoHideTimeout = setTimeout(() => close(), Math.max(autoHideTimerLeftMin, autoHideTimerLeft));
        autoHideTimerStart = Date.now();
    } else if (autoHideTimeout) {
        autoHideTimerLeft -= Date.now() - autoHideTimerStart;
        clearTimeout(autoHideTimeout);
        autoHideTimeout = null;
    }
});

watch(() => props.text, () => {
    const activeToast = findToastByMessage(props.text ?? '');

    if (activeToast?.bind.autoHide === undefined || activeToast?.bind.autoHide) {
        autoHideTimeout = setTimeout(() => close(), Math.max(autoHideTimerLeftMin, autoHideTimerLeft));
        autoHideTimerStart = Date.now();
    }
});

onMounted(() => {
    rearrange();
    $emitter.$on('toast.z-position', onZPositionUpdate);
});

onUnmounted(() => {
    $emitter.$off('toast.z-position', onZPositionUpdate);
});
</script>

<style lang="scss">
.toast {
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    width: rem(345);
    min-height: rem(77);
    padding: 1rem;
    margin-top: -0.75rem;
    border-radius: 0.675rem;
    opacity: 0;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    transition: 0.3s ease;
    transition-property: opacity, margin, transform;
    z-index: 5000;
    white-space: pre-line;

    @include helper-font-size(default);
    @include helper-color(white);
    @include helper-color-bg(white);

    &--success {
        background-color: $setting-color-notification-success;
    }

    &--warning {
        background-color: $setting-color-notification-warning;
    }

    &--error {
        background-color: $setting-color-notification-error;
    }

    &--info {
        background-color: $setting-color-notification-info;
    }

    &--front {
        margin-top: 0;
        opacity: 1;
    }

    &--middle {
        transform: perspective(700px) translate3d(0, 0, -45px);
        margin-top: 0.625rem;
        opacity: 1;
    }

    &--last {
        transform: perspective(700px) translate3d(0, 0, -90px);
        margin-top: 1.25rem;
        opacity: 1;
    }

    &__body {
        display: flex;
        align-items: center;
    }

    &__icon {
        padding-right: 1.25rem;
    }

    &__content {
        flex: 1;
    }

    &__headline {
        margin-bottom: 0.25rem;

        @include helper-font-weight(medium);
    }

    &__buttons {
        margin-top: sp(xxs);

        button {
            border: none;
            background-color: transparent;
            text-decoration: underline;
            cursor: pointer;

            @include helper-color(white);

            & + button {
                margin-left: sp(xs);
            }
        }
    }

    &__close {
        background-color: transparent;
        align-self: flex-start;
        padding-left: 0.5rem;
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.15s ease;

        &:hover {
            opacity: 1;
        }
    }
}
</style>
